import {useMapContext} from '@/components/MindMap/index.mjs'
import ModulePropertiesPane from '../_MODULE/ModulePropertiesPane.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import ButtonExportModel from '../components/PropertiesPaneButtonExportModel.jsx'
import Model from './ModelBm.mjs'

const PropertiesPaneBm = (props) => {
    const map = useMapContext()
    const {mapTypeCode, prjTypeCode, subTypeCode} = map.data
    const [node] = map.useSelectedNodes()

    const buttons = (() => {
        if ('SA_MAP' === mapTypeCode) {
            for (const n of map.trueChain(node.parent)) {
                const {bizNodeType} = n.data

                if (bizNodeType !== node.data.bizNodeType) {
                    if ('CAT_DATA' === bizNodeType) {
                        return null
                    }
                }
            }
        }

        if (
            'BM' === prjTypeCode ||
            'BODA2' === subTypeCode
        ) {
            return (
                <>
                    <ButtonExportModel type="bm" />
                    <ButtonExportModel type="bdterm" />
                    <ButtonExportModel type="bdrule" />
                    <ButtonExportModel type="bdsummary" />
                    <ButtonExportModel type="bdlogic" />
                </>
            )
        }
        else {
            return (
                <>
                    <ButtonExportModel type="bm" />
                    <ButtonExportModel type="bf" />
                    <ButtonExportModel type="bdterm" />
                    <ButtonExportModel type="bdrule" />
                    <ButtonExportModel type="bdsummary" />
                    <ButtonExportModel type="bdlogic" />
                </>
            )
        }
    })()

    return (
        <ModulePropertiesPane
            buttons={buttons}

            controllers={
                <>
                    <Text prop="bmNo" />
                    <Text prop="bmName" />
                </>
            }

            Model={Model}

            snSuffix={
                <Analyse
                    modes={[
                        'Reference',
                        'ReferenceCall',
                    ]}
                />
            }

            versionLabel="业务地图版本号"
            {...props}
        />
    )
}

export default PropertiesPaneBm
