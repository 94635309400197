import Table from '@/components/Table/Table.jsx'
import TableChooseProduct from '../_PRODUCT/TableChooseProduct.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'

const TableChooseUi = (props) => {
    const map = useMapContext()
    const {prjTypeCode, subTypeCode} = map.data

    const columnModule = (() => {
        if (
            'BM' === prjTypeCode ||
            'BODA2' === subTypeCode
        ) {
            return {
                title: '业务域',
                dataIndex: 'bmNamePath',
                component: <Table.ViewText />,
            }
        }
        else {
            return {
                title: '功能模块',
                dataIndex: 'fmNamePath',
                component: <Table.ViewText />,
            }
        }
    })()

    const columns = [
        columnModule,

        {
            title: '子系统',
            dataIndex: 'ssName',
            component: <Table.ViewText />,
        },

        'BCT' === map.data.prjTypeCode && {
            title: '界面方案',
            dataIndex: 'uiCase',
            component: <Table.ViewText />,
        },

        {
            title: '界面代码',
            dataIndex: 'uiUserCode',
            component: <Table.ViewText />,
        },

        {
            title: '界面名称',
            dataIndex: 'uiName',
            component: <Table.ViewText />,
        },
    ].filter(a => a)

    return (
        <TableChooseProduct
            columns={columns}
            {...props}
        />
    )
}

export default TableChooseUi
