import {Col, Form, Input, Row} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'
import dict from '@/script/dict.mjs';
import { Select } from 'antd';
const FormItemPrjName = (props) => {
    return (
        <Form.Item
            label="项目名称"
            name="prjName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemPrjNo = (props) => {
    return (
        <Form.Item
            label="项目编号"
            name="prjNo"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemTypeName = (props) => {
    return (
        <Form.Item
            label="项目类型"
            name="prjTypeCode"
            {...props}
        >
            <Select placeholder="请选择" allowClear>
                {
                    dict.getDictDtls('PRJ_TYPE').filter(item => item.code != 'SLN').map(item => {
                        return <Option key={item.code} value={item.code}>{item.nameCn}</Option>
                    }
                    )
                }
            </Select>
        </Form.Item>
    )
}

export default function FormChooseDe(props) {
    const brief = (
        <Row gutter={12}>
            <Col span={6}>
                <FormItemPrjNo />
            </Col>
             <Col span={6}>
                <FormItemPrjName />
            </Col>
            <Col span={6}>
                <FormItemTypeName />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}
