import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaSs.mjs'
import Model from './ModelSs.mjs'
import PropertiesPane from './PropertiesPaneSs.jsx'
import FormChoose from './FormChooseSs.jsx'
import TableChoose from './TableChooseSs.jsx'
import api from './apiSs.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            if ('SA_MAP' === map.data.mapTypeCode) {
                return /^(VM)$/.test(type)
            }
            else {
                return ProductNode.canMountType.call(this, map, node, type)
            }
        },

        exportTree(map, node, options) {
            if ('SA_MAP' === map.data.mapTypeCode) {
                return this._exportTree(map, node, options)
            }
            else {
                return ProductNode.exportTree.call(this, map, node, options)
            }
        },

        getIcons() {
            return [
                <IconLetters
                    key="type"
                    fill={Color.DARK_GOLD}
                    letters="S"
                    textColor="#fff"
                />
            ]
        },

        getStyle(map, node) {
            const {isUi} = node.data

            return this._getStyle(map, node, {
                borderColor: {
                    0: Color.GREEN_YELLOW,
                    1: Color.LIGHT_BLUE,
                    2: Color.GOLD,
                }[isUi]
            })
        },

        _pushDataSlots: {
            archVmList: [],
            archSsInss: [],
        },

        _getCategories(map, node) {
            return [
                'FC_FA',
                'SS_SSINS',
            ]
        },

        async _grow(map, node, depth) {
            const newDepth = await ProductNode._grow.call(
                this, map, node, depth
            )

            if (newDepth === depth) {
                return depth
            }

            const p = node.trueParent

            if (p) {
                const {bizNodeType} = p.data

                if ('DEP_SS' === bizNodeType) {
                    for (const n of [...node.children]) {
                        if ('SS_SSINS' === n.data.bizNodeType) {
                            map.deleteNode(n)
                        }
                        else {
                            map.deleteTree(n)
                        }
                    }
                }
                else if ('SS_SS' === bizNodeType) {
                    for (const n of [...node.children]) {
                        if ('FC_FA' === n.data.bizNodeType) {
                            map.deleteNode(n)
                        }
                        else {
                            map.deleteTree(n)
                        }
                    }
                }
            }

            return newDepth
        },
    })
}
