import {cloneElement} from 'react'
import {css} from '@emotion/react'
import useSwrNoCache from '@/script/useSwrNoCache.mjs'
import {Button, Select} from 'antd'
import Table from '@/components/Table/Table.jsx'
import { useEffect, useState  } from 'react'

const { Option } = Select
const ButtonSelect = ({item, onSelect, ...props}) => {
    const handleClick = () => onSelect(item)

    return (
        <Button
            size="small"
            type="link"
            onClick={handleClick}
            {...props}
        >
            选择
        </Button>
    )
}

const cssOperates = css({
    display: 'flex',
    justifyContent: 'center',
    gap: 4,
})

const Operates = ({item, operates = [], onSelect, ...props}) => {
    const children = [<ButtonSelect key="select" />, ...operates].map(
        e => cloneElement(e, {item, onSelect})
    )

    return (
        <div
            css={cssOperates}
            onClick={e => e.stopPropagation()}
            {...props}
        >
            {children}
        </div>
    )
}

export default function TableChooseDesign({
    columns,
    fetch,
    multiple,
    operates,
    query,
    onChange,
    onSelect,
    ...props
}) {
    const {data, isLoading} = useSwrNoCache(
        () => fetch(query),
        {dependencies: [query]}
    )
    const [dataSource, setDataSource] = useState([])

    useEffect(() => {
        const newData = data?.map(item => {
            let ower;
            switch (item.prjOwnerTypeCode) {
                case "USER": ower =  item.crtName; break;
                case "TEAM": ower =  item.teamName;
            }
            return {
                userAndTeam: ower,
                ...item
            }
        })
        setDataSource(newData)
    },[data])

    const rowSelection = {
        onChange: (_, selectedRows) => onChange(selectedRows),
        type: multiple ? 'checkbox' : 'radio',
    }


    const enhancedColumns = [
        ...columns,

        // {
        //     title: '分支号',
        //     dataIndex: 'ver',
        //     getValue: ({rev, sVer}) => `${sVer}.${rev}`,
        //     component: <Table.ViewText />,
        // },

        {
            title: '操作',
            dataIndex: '_op',

            component: (_, row) => {
                return (
                    <Operates
                        item={row}
                        operates={operates}
                        onSelect={onSelect}
                    />
                )
            },
        },
    ]

    return (
        <Table
            columns={enhancedColumns}
            dataSource={dataSource}
            loading={isLoading}
            rowKey="pkid"
            onChange={data => setDataSource(data)}
            rowSelection={rowSelection}
            {...props}
        />
    )
}
