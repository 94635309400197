import inlineSvg from '@/script/inlineSvg.mjs'

export default (draw, textRect, paddings, style) => {
    const [pt, pr, pb, pl] = paddings
    const w = pl + textRect.width + pr
    const h = pt + textRect.height + pb

    return inlineSvg(`
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="${style.backgroundColor}"
            stroke="${style.borderColor}"
            stroke-width="${style.borderWidth}"
        >
            ${draw(w, h)}
        </svg>
    `)
}
