import useSwrNoCache from '@/script/useSwrNoCache.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import CategoryRootPropertiesPane from '../_CATEGORY_ROOT/CategoryRootPropertiesPane.jsx'
import ButtonImportProducts from './PropertiesPaneButtonImportProducts.jsx'

export default function CatRootModulePropertiesPane({
    buttons = <ButtonImportProducts />,
    ...props
}) {
    const map = useMapContext()
    const [n] = props.nodes
    const {bizNodeType} = n.data

    const {
        moduleIdProp,
        moduleType,
    } = map.BizNode[bizNodeType]

    const {
        api,
        codeProp,
        name,
        textProp,
    } = map.BizNode[moduleType]

    const pkid = n.data[moduleIdProp]

    const {data} = useSwrNoCache(
        () => api.read({pkid}),
        {dependencies: [pkid]}
    )

    if (! data) {
        return null
    }

    return (
        <CategoryRootPropertiesPane
            buttons={buttons}

            controllers={
                <>
                    <Text
                        label={`${name}代码`}
                        prop={codeProp}
                    />

                    <Text
                        label={`${name}名称`}
                        prop={textProp}
                    />
                </>
            }

            properties={data}
            {...props}
        />
    )
}
