import {usePreviewImages} from '@/components/PreviewImages/PreviewImages'
import openWindow from '@/script/openWindow.mjs'
import runAsync from '@/script/runAsync.mjs'
import Tree from '@/script/Tree.mjs'

export default () => {
    const previewImages = usePreviewImages()

    return (map, ui, event) => runAsync(async () => {
        const rev = map.BizNode.UI.getRev(map, ui)
        const {pkid} = ui.data
        const treeData = await map.BizNode.UI.readTree({pkid, rev})
        const tree = new Tree(treeData)

        for (const n of tree.children(tree.root)) {
            if ('UI_DEMO' !== n.data.bizNodeType) {
                continue
            }

            const next = chain => {
                const {bizNodeType} = chain[0].data
                const yieldChildren = 'CAT' === bizNodeType
                const yieldNode = 'UV' === bizNodeType
                return {yieldChildren, yieldNode}
            }

            const [uv] = [...tree.dfs(tree.children(n), next)]

            if (! uv) {
                throw new Error('未找到界面视图')
            }

            const {demoType} = uv.data

            ;({
                DEMO: () => {
                    const {pkid: uvId} = uv.data

                    openWindow(
                        '/formilyPreview',
                        {isTemplate: false, uvId},
                        event.altKey ? '_self' : '_blank'
                    )
                },

                IMG: () => {
                    const urls = [...tree.children(uv)]
                        .filter(e => e.data.fileId)
                        .map(e => {
                            const {fileId} = e.data
                            const base = import.meta.env.VITE_HTTP_BASE_URL
                            return `${base}/files/${fileId}`
                        })

                    if (0 < urls.length) {
                        previewImages(urls)
                    }
                    else {
                        throw new Error('未找到原型图')
                    }
                },
            })[demoType]()
        }
    })
}
