import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    prjName: {
        name: () => '项目名称',
        required: () => true,
        readOnly: () => true,
    },
    prjNo: {
        name: () => '项目编号',
        required: () => true,
        readOnly: () => true,
    },
    sVer: {
        name: () => '分支号',
        required: () => true,
        readOnly: () => true,
    },
    pblName: {
        name: () => '分支名称',
        required: () => true,
        readOnly: () => true,
    },
    uptName: {
        name: () => '更新人',
        required: () => true,
        readOnly: () => true,
    },
    typeName: {
        name: () => '项目类型',
        required: () => true,
        readOnly: () => true,
    },
    uptTime: {
        name: () => '更新时间',
        required: () => true,
        readOnly: () => true,
    },
    crtTime: {
        name: () => '创建时间',
        required: () => true,
        readOnly: () => true,
    },
    crtName: {
        name: () => '创建人',
        required: () => true,
        readOnly: () => true,
    },
})
