import {Col, Form, Input, Row} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'
import SelectBm from '../BM/SelectBm.jsx'
import SelectPm from '../PM/SelectPm.jsx'

const FormItemBms = (props) => {
    return (
        <Form.Item
            label="业务域"
            name="bms"
            {...props}
        >
            <SelectBm multiple />
        </Form.Item>
    )
}

const FormItemPms = (props) => {
    return (
        <Form.Item
            label="流程域"
            name="pms"
            {...props}
        >
            <SelectPm multiple />
        </Form.Item>
    )
}

const FormItemBfNo = (props) => {
    return (
        <Form.Item
            label="流程代码"
            name="bfNo"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemBfName = (props) => {
    return (
        <Form.Item
            label="流程名称"
            name="bfName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormChooseBf = (props) => {
    const map = useMapContext()
    const {prjTypeCode, subTypeCode} = map.data

    const formItemModule = (() => {
        if (
            'BM' === prjTypeCode ||
            'BODA2' === subTypeCode
        ) {
            return <FormItemPms />
        }
        else {
            return <FormItemBms />
        }
    })()

    const brief = (
        <Row gutter={12}>
            <Col span={6}>
                {formItemModule}
            </Col>

            <Col span={6}>
                <FormItemBfNo />
            </Col>

            <Col span={6}>
                <FormItemBfName />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}

export default FormChooseBf
