import Table from '@/components/Table/Table.jsx'
import TableChooseComponent from './TableChooseDesign.jsx'
import { Tag, Select } from 'antd'

const { Option } = Select

// 分支号列表
const SverList = ({
    value = 1,
    arrData,
    ...props
}) => {
    if (arrData.length == 1) {
        return <div>{arrData.map(v => <span value={v.sVer} >{'[' + v.sVer + ']' + v.pblName}</span>)}</div>
    }
    if (arrData.length > 1) {
        return (
            <Select
                value={value}
                style={{ width: '150px' }}
                size='small'
                showSearch
                onClick={e => e.stopPropagation()}
                filterOption={(input, option) => {
                    return (option?.children ?? '').includes(input + '');
                }}
                onChange={e => {
                    props.onChange(e)
                }}
            >
                {
                    arrData.map(v => <Option value={v.sVer} >{'[' + v.sVer + ']' + v.pblName}</Option>)
                }
            </Select>
        )
    }

    return <div>[1]主分支</div>
}
export default function TableChooseDe({sVerMap, ...props}) {
    const columns = [
        {
            title: '项目编号',
            dataIndex: 'prjNo',
            component: <Table.ViewText />,
        },
        {
            title: '项目名称',
            dataIndex: 'prjName',
            component: <Table.ViewText />,
        },
        {
            title: '分支号',
            dataIndex: 'newSVer',
            width: 182,
            component: (value, record) => {
                const prjBaseLines = record.prjBaseLines ?? [];
                return (<SverList arrData={prjBaseLines} />)
            },
           
        },
        {
            title: '项目类型',
            dataIndex: 'typeName',
            component: <Table.ViewText />,
            filter: <Table.FilterText />,
        },

        {
            title: '项目所有者',
            dataIndex: 'userAndTeam',
            width: 160,
            component: <Table.ViewText />,
            filter: <Table.FilterText  />,
            render: (text, record) => {
                switch (record.prjOwnerTypeCode) {
                    case "USER": return <div><Tag color="yellow">个人</Tag>{text}</div>;
                    case "TEAM": return <div><Tag color="blue">团队</Tag>{text}</div>;
                }
            }
        },
        // {
        //     title: '创建人',
        //     dataIndex: 'crtName',
        //     width: 180,
        //     ellipsis: true,
        //     filter: <Table.FilterText/>,
        //     component: <Table.ViewText />
        // },
        // {
        //     title: '创建时间',
        //     dataIndex: 'crtTime',
        //     width: 160,
        //     component: <Table.ViewText />
        // },
    ]

    return (
        <TableChooseComponent
            columns={columns}
            {...props}
        />
    )
}
