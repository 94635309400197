import CatRootModulePropertiesPane from '../_CAT_ROOT_MODULE/CatRootModulePropertiesPane.jsx'

export default function PropertiesPaneCatRootUm(props) {
    return (
        <CatRootModulePropertiesPane
            buttons={null}
            {...props}
        />
    )
}
