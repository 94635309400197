import {Button} from 'antd'
import openWindow from '@/script/openWindow.mjs'
import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

const PropertiesPaneButtonPreview = (props) => {
    const [{prjId, sVer=1}] = useProperties()

    
    if (!prjId) {
        return null
    }

    const handleClick = e => {
        const target = e.altKey ? '_self' : '_blank'
        openWindow('/formilyFrame', {prjId, sVer}, target)
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >
            预览
        </Button>
    )
}

export default PropertiesPaneButtonPreview
