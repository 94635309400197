import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaOoOa.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canWriteTree(map, node) {
            return false
        },

        exportTree(map, node, options) {
            return this._exportTree(map, node, options)
        },
    })
}
