import {useMapContext} from '@/components/MindMap/index.mjs'
import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Bm from '../BM/PropertiesControllerBm.jsx'
import Fm from '../FM/PropertiesControllerFm.jsx'
import Ss from '../SS/PropertiesControllerSs.jsx'
//import ButtonDemo from './PropertiesPaneButtonDemo.jsx'
import ButtonPreview from './PropertiesPaneButtonPreview.jsx'
import Model from './ModelUi.mjs'

export default function PropertiesPaneUi(props) {
    const map = useMapContext()
    const {prjTypeCode, subTypeCode} = map.data

    const module = (() => {
        if (
            'BM' === prjTypeCode ||
            'BODA2' === subTypeCode
        ) {
            return <Bm />
        }
        else {
            return <Fm />
        }
    })()

    return (
        <ProductPropertiesPane
            buttons={
                <>
                    {/* <ButtonDemo /> */}
                    <ButtonPreview />
                </>
            }

            controllers={
                <>
                    <Ss isUi={1} />
                    {module}
                    <Text prop="uiUserCode" />
                    <Text prop="uiName" />
                    <LongText prop="memo" />
                </>
            }

            Model={Model}

            snSuffix={
                <Analyse
                    modes={[
                        'ChgReference',
                        'CallUiDepend',
                        'CallUiEffect',
                        'Depend',
                        'Effect',
                        'Reference',
                        'ReferenceCall',
                    ]}
                />
            }

            {...props}
        />
    )
}
