import ButtonImportModules from '../components/PropertiesPaneButtonImportModules.jsx'
import ArchPropertiesPane from '../_ARCH/ArchPropertiesPane.jsx'
import ButtonPreview from './PropertiesPaneButtonPreview.jsx'

export default function PropertiesPaneUa(props) {
    return (
        <ArchPropertiesPane
            buttons={
                <>
                    <ButtonImportModules type="um" />
                    <ButtonPreview />
                </>
            }

            {...props}
        />
    )
}
