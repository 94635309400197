import usePermission from '@/hooks/usePermission.mjs'

const Permission = ({
    children,
    permission,
    permissions = [],
    project,
    projects = [],
    team,
    teams = [],
    conditions = true,
}) => {
    const hasPermission = usePermission(
        permission ? [permission, ...permissions] : permissions,
        project ? [project, ...projects] : projects,
        team ? [team, ...teams] : teams,
    ) && conditions

    return hasPermission ? children : null
}

export default Permission
